import { v4 as uuidv4 } from 'uuid';
import type {
  AnalyticsContext,
  ExperimentServiceVariantServer,
  ExperimentSource,
} from '../../types';
import { setCookie, getCookie } from '../../utils/cookies';
import { getBaseUrl } from '../../utils/requests';
import amplitude from '../amplitude';
import { isDatadogSyntheticTest } from '../datadog';

export const UUID_COOKIE_NAME = 'whoop-experiment-uuid';

const experimentServiceVariants: Record<string, string> = {};

const fetchVariant = async ({ filters, name, headers }) => {
  if (isDatadogSyntheticTest()) return null;

  const body = filters ? JSON.stringify(filters) : undefined;
  try {
    const variantResponse = await fetch(
      `${getBaseUrl()}/experiment-service/v1/experiments/name/${name}/variant`,
      {
        method: 'POST',
        headers,
        body,
      },
    );
    if (variantResponse.status !== 200) {
      return null;
    }

    const variant = await variantResponse.text();
    return variant;
  } catch (error) {
    return null;
  }
};

export default {
  getVariant: async (name: string, filters?: AnalyticsContext) => {
    if (experimentServiceVariants[name]) {
      return experimentServiceVariants[name];
    }
    const cookieValue = getCookie<string>(UUID_COOKIE_NAME);
    const uuid = cookieValue ? decodeURIComponent(cookieValue) : uuidv4();
    const headers = {
      'Content-Type': 'application/json',
      'X-WHOOP-Installation-Identifier': uuid,
    };

    const variant = await fetchVariant({ headers, name, filters });
    if (variant) {
      setCookie<string>(UUID_COOKIE_NAME, uuid);
      if (amplitude.setUserProperty) {
        amplitude.setUserProperty(name, variant);
        amplitude.setUserProperty('experiment_service_id', uuid);
      }
      experimentServiceVariants[name] = variant;
      return variant;
    }

    return null;
  },
  getVariantByUserToken: async (
    name: string,
    userToken: string,
    filters?: AnalyticsContext,
  ) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    };
    const body = filters ? JSON.stringify(filters) : undefined;
    try {
      const variantResponse = await fetch(
        `${getBaseUrl()}/experiment-service/v1/experiments/name/${name}/user-variant`,
        {
          method: 'POST',
          headers,
          body,
        },
      );
      if (variantResponse.status !== 200) {
        return null;
      }
      const variant = await variantResponse.text();
      return variant;
    } catch (_err) {
      return null;
    }
  },
  getVariantOnServer: async ({
    name,
    filters,
    experimentCookie,
  }: ExperimentServiceVariantServer) => {
    let uuid = experimentCookie;
    if (uuid) {
      const decodedUuid = decodeURIComponent(uuid);
      try {
        const parsedUuid = JSON.parse(decodedUuid);
        uuid = parsedUuid;
      } catch (error) {
        console.info('JSON parsing of token failed');
      }
    } else {
      console.info('No existing token found, generating a new one');
      uuid = uuidv4();
    }

    const headers = {
      Accept: 'application/json',
      'Accept-Language': 'en',
      'X-WHOOP-Installation-Identifier': uuid,
      cache: 'no-store',
    };

    const variant = await fetchVariant({ headers, name, filters });
    if (variant) {
      return { variant, uuid };
    }
    return { variant: null, uuid };
  },
} as ExperimentSource;
